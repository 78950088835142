import { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {
  DarkModeContext,
  BasicChip,
  CalendarIcon,
  getRequiredDateFormat,
} from "@datwyler/shared-components";

const Chips = (props) => {
  const { filters = {}, getLabel } = props;
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        sx={{ paddingTop: "20px", paddingLeft: "24px" }}
        useFlexGap
        flexWrap="wrap"
      >
        {Object.keys(filters).map((key) => {
          const values = filters[key] || [];
          const size = values.length;
          if (size > 0) {
            return values.map((id) => {
              const label = getLabel(key, id);
              return <BasicChip label={label} key={id} />;
            });
          } else if (key === "endTime" && filters["endTime"]) {
            // only show when there's an enddate
            const label = (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "inline-flex" }}>
                  <CalendarIcon width={"20px"} height={"20px"} />
                </Box>
                <Box sx={{ display: "inline-flex" }}>
                  <Typography
                    sx={{
                      color: colors.blueGray700,
                      fontFamily: "NotoSans-Medium",
                      fontSize: "14px",
                      fontWeight: 500,
                      letterSpacing: "0.01px",
                      lineHeight: "20px",
                      marginLeft: "8px",
                    }}
                  >
                    {getRequiredDateFormat(filters["startTime"], "DD/MM/YY") +
                      " - " +
                      getRequiredDateFormat(filters["endTime"], "DD/MM/YY")}
                  </Typography>
                </Box>
              </Box>
            );
            return <BasicChip label={label} key={key} />;
          }
        })}
      </Stack>
    </Box>
  );
};

export default Chips;
