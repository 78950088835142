import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import AlarmTable from "../../components/AlarmTable";
import SelectionOptions from "../../components/SelectionOptions";
import { useIntl } from "react-intl";
import { ACTIVE, ESCALATED, IN_PROGRESS } from "../../utils/constants";
import FiltersAndOrder from "../../components/FiltersAndOrder";
import EscalateIssueDialog from "../../components/Dialogs/EscalateIssueDialog";
import {
  useAlarm,
  useDevice,
  BasicBreadcrumbs,
  BasicScreenHeader,
  useLoadingGif,
  exportToExcel,
  getRequiredDateFormat,
  useUser,
  TenantIdContext,
  DarkModeContext,
  formatTimeStamp,
  useLocation,
  useSnackbar,
} from "@datwyler/shared-components";

const filterDefaults = {
  site: [],
  deviceType: [],
  severity: [],
  status: [ACTIVE, IN_PROGRESS],
  assignedTo: [],
  startTime: null,
  endTime: null,
};

const Alarm = () => {
  const { tenantId }: any = useContext(TenantIdContext);
  const {
    isFetchAlarmLoading,
    fetchAlarmData,
    fetchAlarms,
    updateAlarms,
    isUpdateAlarmLoading,
    assignAlarms,
    isAssignAlarmsLoading,
    exportAlarms,
    isExportAlarmsLoading,
    exportAlarmsData,
    escalationHistory,
    escalationHistoryLoading,
    escalationHistoryData,
    unassignAlarms,
    isUnassignAlarmsLoading,
  } = useAlarm();
  const { isFetchUsersLoading, fetchUsersData, fetchUsers } = useUser();
  const {
    isFetchLoading: isFetchLocationLoading,
    fetchLocationData,
    fetchLocations,
  } = useLocation();
  const { LoadingGif, setIsLoading } = useLoadingGif();
  const [filters, setFilters] = useState(filterDefaults);
  const [order, setOrder] = useState("time");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [selected, setSelected] = useState([]);
  const intl = useIntl();
  const { colors }: any = useContext(DarkModeContext);
  const [showEscalateIssue, setShowEscalateIssue] = useState(false);

  useEffect(() => {
    setIsLoading(
      isFetchAlarmLoading ||
        isUpdateAlarmLoading ||
        isFetchUsersLoading ||
        isFetchLocationLoading ||
        isExportAlarmsLoading ||
        isAssignAlarmsLoading ||
        isUnassignAlarmsLoading ||
        false
    );
  }, [
    isFetchAlarmLoading,
    isUpdateAlarmLoading,
    isFetchUsersLoading,
    isFetchLocationLoading,
    isAssignAlarmsLoading,
    isExportAlarmsLoading,
  ]);

  useEffect(() => {
    fetchLocations({
      variables: { tenantId: tenantId },
    });

    fetchUsers({
      variables: {
        tenantId: tenantId,
        page: { number: 0, size: 999999 }, // Big number to get all data
      },
    });
  }, [tenantId]);

  useEffect(() => {
    if (fetchAlarmData?.alarms?.page) {
      setTotalRows(fetchAlarmData?.alarms?.page.totalElements || 0);
    }
  }, [fetchAlarmData]);

  useEffect(() => {
    refetchAlarms();
  }, [filters, order, page, rowsPerPage, tenantId]);

  useEffect(() => {
    if (exportAlarmsData?.alarms?.alarms) {
      exportToExcel(
        getExcelData(),
        intl.formatMessage({ id: "excel_filename_alarm" })
      );
    }
  }, [exportAlarmsData]);

  const getFilters = () => {
    const filtersToSend = JSON.parse(JSON.stringify(filters)) || {};
    if (filters.site) {
      filtersToSend.site = filters.site.map((id) => ({
        id: id,
      }));
    }
    if (filters.assignedTo) {
      filtersToSend.assignedTo = filters.assignedTo.map((id) => ({
        id: id,
      }));
    }
    if (filtersToSend.endTime) filtersToSend.endTime = adjustEndTime();
    return filtersToSend;
  };

  const adjustEndTime = () => {
    const endTime = new Date(formatTimeStamp(filters.endTime));
    endTime.setDate(endTime.getDate() + 1);
    return Math.floor(endTime.getTime() / 1000);
  };

  const getSort = () => {
    let sort = "";

    if (order) {
      switch (order) {
        case "time":
          sort = `time,desc`;
          break;
        case "assignedTo":
          sort = `assignedTo,asc`;
          break;
        case "updatedOn":
          sort = `updatedOn,desc`;
          break;
        case "status":
          sort = `status,asc`;
          break;
        case "severity":
          sort = `severity,asc`;
          break;
        default:
          sort = `time,desc`;
      }
    }

    return sort;
  };

  const refetchAlarms = () => {
    const filtersToSend = getFilters();
    if (!filtersToSend) return;
    const sort = getSort();

    fetchAlarms({
      variables: {
        tenantId: tenantId,
        filter: filtersToSend,
        sort: [sort],
        page: { number: page, size: rowsPerPage },
      },
    });
  };

  const getLowerComponent = () => {
    if (selected.length > 0) {
      return (
        <>
          <Divider sx={{ borderColor: colors.dividerColor }} />

          <SelectionOptions
            selected={selected}
            setSelected={setSelected}
            updateAlarms={updateAlarms}
            setShowEscalateIssue={setShowEscalateIssue}
            tenantId={tenantId}
          />
        </>
      );
    } else {
      return (
        <>
          <Divider sx={{ borderColor: colors.dividerColor }} />
          <FiltersAndOrder
            setFilters={setFilters}
            filters={filters}
            order={order}
            setOrder={setOrder}
            locations={fetchLocationData?.locations}
            users={fetchUsersData?.users?.users}
            filterDefaults={filterDefaults}
          />
        </>
      );
    }
  };

  const fetchDataForExcel = () => {
    const filtersToSend = getFilters();
    const sort = getSort();

    exportAlarms({
      variables: {
        tenantId: tenantId,
        filter: filtersToSend,
        sort: [sort],
        page: { number: 0, size: 999999 },
      },
    });
  };

  const getExcelData = () => {
    const excelData = exportAlarmsData?.alarms?.alarms?.map((data) => {
      const row = {};

      row[intl.formatMessage({ id: "id" })] = data.id || "-";
      row[intl.formatMessage({ id: "device" })] = data.device?.name || "-";
      row[intl.formatMessage({ id: "alarm_tbl_col_header_site" })] =
        data.device?.site?.name || "-";
      row[intl.formatMessage({ id: "alarm_tbl_col_header_attribute" })] = data
        .values?.attribute_name
        ? intl.formatMessage({ id: data.values?.attribute_name })
        : "-";
      row[intl.formatMessage({ id: "alarm_tbl_col_header_description" })] =
        data.description || "-";
      row[intl.formatMessage({ id: "alarm_tbl_col_header_severity" })] =
        data.severity
          ? intl.formatMessage({ id: data.severity.toLowerCase() })
          : "-";
      row[intl.formatMessage({ id: "date" })] = data.time
        ? getRequiredDateFormat(data.time, "DD/MM/YY HH:mm")
        : "-";
      row[intl.formatMessage({ id: "status" })] = data.status
        ? intl.formatMessage({ id: data.status.toLowerCase() })
        : "-";
      (row[intl.formatMessage({ id: "alarm_tbl_col_header_assigned_to" })] =
        data.assignedTo
          ? data.assignedTo.firstName + " " + data.assignedTo.lastName
          : "-"),
        (row[intl.formatMessage({ id: "last_updated_by" })] = data.updatedBy
          ? data.updatedBy.firstName + " " + data.updatedBy.lastName
          : "-");
      row[intl.formatMessage({ id: "last_updated_time" })] = data.updatedOn
        ? getRequiredDateFormat(data.updatedOn, "DD/MM/YY HH:mm")
        : "-";

      return row;
    });
    return excelData;
  };

  const escalateAlarms = (alarmDescription) => {
    const selectedIds = selected.map((alarm) => alarm.id);
    const status = ESCALATED;
    updateAlarms({
      variables: {
        input: {
          tenant: { id: tenantId },
          alarmIds: selectedIds,
          status: status,
          ...(alarmDescription && { message: alarmDescription }),
        },
      },
    });
    setSelected([]);
    setShowEscalateIssue(false);
  };

  return (
    <Box sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}>
      <LoadingGif />
      <BasicBreadcrumbs activePage={"Alarm"} />
      <BasicScreenHeader
        title={intl.formatMessage({ id: "title_alarm" })}
        exportButton={{
          label: intl.formatMessage({ id: "export" }),
          onClick: fetchDataForExcel,
        }}
        LowerComponent={getLowerComponent()}
      />
      <AlarmTable
        alarmData={fetchAlarmData?.alarms?.alarms}
        selected={selected}
        setSelected={setSelected}
        users={fetchUsersData?.users?.users}
        updateAlarms={updateAlarms}
        assignAlarms={assignAlarms}
        unassignAlarms={unassignAlarms}
        escalationHistory={escalationHistory}
        escalationHistoryLoading={escalationHistoryLoading}
        escalationHistoryData={escalationHistoryData}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        totalRows={totalRows}
        tenantId={tenantId}
      />
      <EscalateIssueDialog
        open={showEscalateIssue}
        onClose={setShowEscalateIssue}
        escalateAlarmsHandler={escalateAlarms}
      />
    </Box>
  );
};

export default Alarm;
