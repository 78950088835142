import { useState, useEffect, useContext } from "react";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import { useIntl } from "react-intl";
import { ACTIVE } from "../../utils/constants";
import { SearchBox, DarkModeContext } from "@datwyler/shared-components";

const AssignedToDialog = (props) => {
  const { anchorEl, setAnchorEl, alarmData, users, assignAlarms, tenantId } =
    props;
  const intl = useIntl();
  const [options, setOptions] = useState([]);
  const { colors }: any = useContext(DarkModeContext);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setOptions(getSearchOptions());
  }, [users]);

  const getSearchOptions = () => {
    const options = [];
    users?.forEach((user) => {
      if (user.status === ACTIVE)
        options.push({
          label: user.firstName + " " + user.lastName,
          value: user.id,
        });
    });

    return options;
  };

  const handleOnSelect = (userId) => {
    const alarm = getAlarmFromId();

    const alarmRequest = {
      tenant: { id: tenantId },
      alarmIds: [alarm.id],
      assignee: { id: userId },
    };
    assignAlarms({
      variables: { input: { ...alarmRequest } },
    });

    handleClose();
  };

  const getAlarmFromId = () => {
    const alarmId = parseInt(anchorEl.id);
    return alarmData?.find((alarm) => alarm.id === alarmId);
  };

  return (
    <Popover
      id={"assigned_to_dialog"}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        sx: {
          boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
          borderRadius: "8px",
          padding: "8px",
          overflow: "hidden",
          backgroundColor: colors.cardBg,
        },
      }}
    >
      <Box sx={{ width: "297px" }}>
        <SearchBox
          inputPlaceholder={intl.formatMessage({ id: "search" })}
          options={options}
          onSelect={handleOnSelect}
        />
      </Box>
    </Popover>
  );
};

export default AssignedToDialog;
